@import '../../styles/colors';

$track-background-color: $color-black;
$track-foreground-color: $color-brand-amber;

$thumb-color: $color-brand-amber;
$thumb-dragged-color: $color-black;
$thumb-border-color: $color-black;

.priceRange {
  margin-bottom: 1rem;
  max-width: 30rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
  text-transform: uppercase;
}

.trackOuter {
  display: flex;
  height: 2rem;
  width: 100%;
}

.trackInner {
  align-self: center;
  border-radius: 10px;
  box-shadow: 0 1px $track-background-color;
  height: 0.3rem;
  width: 100%;
}

.thumb {
  border-radius: 50%;
  border: 1px solid $thumb-border-color;
  height: 1.7rem;
  transition: background-color 150ms linear;
  width: 1.7rem;

  &:hover,
  &.thumbActive {
    box-shadow: 0 0 0.3rem 0.1rem rgba($color-black, 0.5);
  }
}

.output {
  font-size: 1.2rem;
  letter-spacing: 0.03rem;
  padding: 0.5rem 0 0;
  text-align: center;
}

:export {
  trackBackgroundColor: $track-background-color;
  trackForegroundColor: $track-foreground-color;
  thumbColor: $thumb-color;
  thumbDraggedColor: $thumb-dragged-color;
}

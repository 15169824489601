@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}
.mainBiPlatesIframe{

  height: 310vh;
  @media (max-width: $breakpoint-large) and (min-width: $breakpoint-ipad) {
    height: 155vh;
  }
  @media (min-width: $breakpoint-small) and (max-width:$breakpoint-tablet){
    height: 540vh;
  }
  @media (min-width: $breakpoint-medium) and (max-width:$breakpoint-large){
    height: 300vh;
  }
  @media (max-width: $breakpoint-small){
    height: 610vh;
  }
  // @media (max-width: $breakpoint-medium){
  //   height: 420vh;
  // }
  @media (min-width: $breakpoint-large) {
    height: 180vh;
  }


}

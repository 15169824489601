@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-light-grey;
}

.title {
  font-size: 3.6rem;
  margin: 0.7rem 0 3.6rem;
}

.section {
  display: flex;
  margin: 5rem 0 0 0;
}

.platesContainer {
  flex-grow: 1;
  order: 1;
  padding: 0 1.5rem;
  flex-basis: 78rem;
  max-width: 78rem;

  @media (min-width: $breakpoint-tablet) {
    padding: 3rem 0 3rem 5rem;
  }

}
.browseBtnCls {
  margin-top: 100px;

  @media (max-width: $breakpoint-tablet) {
    margin: 20px 15px;
  }
}

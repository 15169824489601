$breakpoint-small: 400px;
$breakpoint-medium: 640px;
$breakpoint-tablet: 768px;
$breakpoint-ipad: 769px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1600px;

:export {
  small: $breakpoint-small;
  medium: $breakpoint-medium;
  tablet: $breakpoint-tablet;
  ipad: $breakpoint-ipad;
  large: $breakpoint-large;
  xlarge: $breakpoint-xlarge;
  .choose_style {
    background-color: red !important;
  }
}

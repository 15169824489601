@import '../../styles/colors';
@import '../../styles/breakpoints.module';

$horizontal-padding: 4.5rem;

.filterHeader {
  font-size: 1.4rem;
  padding: 1.7rem 2.8rem;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    padding: 3.5rem 2rem 1rem $horizontal-padding;
  }

  @media (min-width: 1000px) {
    padding-right: $horizontal-padding;
  }
}

.title {
  margin: 0;
  font-size: 3.6rem;

  @media (min-width: $breakpoint-tablet) {
    font-size: 3.2rem;
  }

  @media (min-width: $breakpoint-large - 200) {
    font-size: 4.5rem;
  }
}

.copy {
  line-height: 1.9rem;
  margin-top: 0;
  padding-right: 1rem;

  @media (min-width: $breakpoint-tablet + 30) {
    padding-right: 30%;
  }
}

.intro {
  flex-grow: 1;
  padding-right: 1rem;

  @media (min-width: $breakpoint-tablet) {
    flex-basis: 50%;
  }
}

.plateTextForm {
  padding-top: 0.4rem;

  @media (min-width: $breakpoint-medium) {
    flex-basis: 50%;
    padding-left: 1rem;
  }
}

.plateTextInput {
  border-radius: 0.36rem;
  border: 1px solid $color-grey;
  caret-color: $color-brand-amber;
  font-size: 3.2rem;
  font-family: inherit;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 0.8rem 1rem 0.4rem;
  max-width: 28rem;
  width: 100%;

  // hide the `x` on the search input as it overlaps the text otherwise.
  &::-webkit-search-cancel-button {
    display: none;
  }

  // normalize sets it to textfield which doesn't work.
  &[type='search'] {
    appearance: none;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 3.7rem;
  }

  @media (min-width: $breakpoint-tablet) {
    font-size: 3.2rem;
  }

  @media (min-width: $breakpoint-tablet + 1) {
    padding: 1.5rem 1.5rem 1.1rem;
    font-size: 3.7rem;
  }

  &::placeholder {
    color: $color-grey;
    opacity: 0.5;
  }
}

@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}
.mainTermsIframe{
  height: 260vh;
  @media (max-width: $breakpoint-large) and (min-width: $breakpoint-ipad) {
    height: 235vh;
  }
  @media (min-width: $breakpoint-small) and (max-width:$breakpoint-tablet){
    height: 405vh;
  }
  @media (min-width: $breakpoint-medium) and (max-width:$breakpoint-large){
    height: 250vh;
  }
  @media (max-width: $breakpoint-small){
    height: 495vh;
  }
  @media (min-width: $breakpoint-large) {
    height: 185vh;
  }
}

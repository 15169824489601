  @import '../../styles/variables';
  @import '../../styles/colors';
  @import '../../styles/breakpoints.module';

  .headerBg {
    background-color: $color-black;
    color: $color-white;
  }

.subHeader {
  background-color: $color-light-grey;
  padding: 2.8rem;

  @media (min-width: $breakpoint-medium) {
    text-align: center;
    padding: 6rem 0;
  }

  h1 {
    font-size: 3.6rem;
    margin: 0;

    @media (min-width: $breakpoint-medium) {
      font-size: 4.8rem;
    }
  }
}

.body {
  padding: 0 2.8rem 8rem;

  @media (min-width: $breakpoint-medium) {
    padding: 2rem 0 9rem;
    text-align: center;
  }

  h2 {
    margin-bottom: 0;
  }
}

.stepList {
  display: flex;

  flex-direction: row;
  list-style: none;
  margin: 0 0 2.8rem;
  padding: 0;
  justify-content: space-around;
  @media (min-width:679px) and (max-width:1022px) {
    flex-wrap: wrap;
  }
  @media (min-width: $breakpoint-medium) {
    flex-direction: row;
    justify-content: center;
  }
}

.mainHowItWorksCheckLI li:last-child svg {
  width: 53px !important;
  transform: scale(0.70);
  height: 66px;
  overflow: visible;
}

.separator {
  border-color: $color-black;
  border-top: 0;
  max-width: 63rem;
}

.action {
  text-align: center;

  @media (min-width: $breakpoint-medium) {
    padding-top: 3rem;
  }
}
.choose_style .mainHowWorkSec {
  background-color: unset !important;
}

.mainHowWorkSec {
  background-color: $color-light-grey;
  padding: 2rem 2rem 5rem 2rem;
  @media (min-width: $breakpoint-medium) {}
}

.h2Howitworks {
  @media (min-width: $breakpoint-medium) {
    font-size: 3.5rem;
  }
}

.mainHowWorkSec ul li span {
  background-color: unset !important;
}

.mainHowWorkSec ul li svg g{
  fill: #000 !important;
}

@media (min-width : $breakpoint-medium) {
  main.choose_style {
    text-align: left !important;
    //padding-left: 30px;
  }
  main.choose_style ol {
      justify-content: flex-start;
  }
  .choose_style .mainHowWorkSec {
    background-color: unset !important;
    padding: 0;
}
.choose_style ul {
    justify-content: flex-start;
}
.choose_style .interstitial{
    text-align: left;
}

  .choose_style ol li:last-child svg {
      width: 53px !important;
      transform: scale(0.70);
      height: 66px;
      overflow: visible;
  }
  .choose_style ol li svg {
      width: 30px;
  }
  .choose_style li {
    @media (min-width:679px) and (max-width:1022px) {
      flex-basis: 50rem;
    }
      flex-direction: row;
      flex-basis: 22rem;
  }

  .choose_style h2 {
      font-size: 3rem;
  }
  .choose_style .mainHowWorkSec h2{
    font-size: 3rem;
  }
  .choose_howItWorksCheckLeft {
    padding-left: 4.5rem;
  }
}

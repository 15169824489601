@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}
.mainFAQIframe{
  //height: 400vh;
  overflow: auto !important;
  min-height: calc(100vh + 395vh); // when all concerntina open
  //min-height: calc(100vh + 170vh); when not open concrtinas
  @media(max-width:480px){
      //height: 400vh;
      overflow: auto !important;
  }
}

@import '../../styles/colors';
@import '../../styles/breakpoints.module';
@import './filter-sidebar.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}

.footerBg {
  background-color: $color-black;
  color: $color-white;
}

.clearfilterBtn {
  padding: 0rem 0rem 5rem 6rem;
  @media (max-width: $breakpoint-tablet) {
    display: none;
   }
}

.mainGridPlatinumClass {
  padding-bottom: 0 !important;
}

.mainGridPlatinumClass .plateGrid {
  padding-bottom: 0 !important;
}

.mainGridPlatinumClass .plateDesignSection p {
  margin-bottom: 100px !important;
  margin-top: 0 !important;
  font-size: 1.5rem;
  padding-left: 7px;
}

.mainGridPlatinumClass .plateDesignSection span.plateDesignSectionSpan {
  display: block;
  margin-bottom: 80px;
  font-size: 1.5rem;
  margin-top: 90px;
}

.mainGridPlatinumClass .plateDesignSection {
  margin-top:0 !important
}

.layout {
  display: flex;
  flex-wrap: nowrap;
}

.plateCreator {
  padding: 0;
  flex-grow: 1;
  flex-basis: 70rem;
  position: relative;
  z-index: 0;
}

.chipDisplay {
  border-bottom: 1px solid $color-black;
  display: none;

  @media (min-width: $breakpoint-tablet) {
   display: block;
  }
}

.purchaseButton {
  margin: 0 0 6rem;
  text-align: left;
}

.plateGrid {
  padding: 0 1rem 6rem;

  @media (min-width: $breakpoint-medium) {
    padding-left: 4.5rem;
    padding-right: 1.8rem; // cater for the negative margins on PlateGrid
  }

  &.noResults {
    padding-left: 2.8rem;
    padding-right: 1rem;

    @media (min-width: $breakpoint-medium) {
      padding-left: 4.5rem;
    }
  }
}

.filterSidebarToggleContainer {
  // border-bottom: 1px solid $color-black;

  padding: 0.5rem 2rem 1.5rem;
  position: relative;
  z-index: 1;

  & + .plateGrid > p:first-of-type {
    margin-bottom: 5.1rem;
  }

  @media (min-width: $breakpoint-medium) {
    padding-left: 3.5rem;
    margin-bottom: -6.4rem;
  }

  @media (min-width: $breakpoint-tablet) {
    display: none;

    & + .plateGrid > p:first-of-type {
      margin-bottom: 1.8rem;
    }
  }
}

.filterSidebarToggle {
  background-color: $color-white;
  background-image: url('../../assets/images/icon-filter.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) 45%;
  border: 0;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  padding: 1rem 5rem 1rem 1rem;
  text-transform: uppercase;
  @media (min-width: $breakpoint-tablet) {
    background-image: unset;
  }
}

.plateDesignSection {

  @media (max-width: $breakpoint-large) and (min-width: $breakpoint-ipad) {
    margin: 0 !important;
    padding-left:4.5rem !important
  }
  @media (max-width: $breakpoint-small) {
    margin: 0 !important;
  }
  @media (max-width: $breakpoint-medium) {
   padding: 0vh 2vh 2vh 2vh
  }
  //@media (max-width: $breakpoint-ipad) {
    //margin: 5vh 5vh 5vh 5vh;
  //}
  @media (min-width: $breakpoint-medium) {
    margin: 0;
  }
  @media (min-width: $breakpoint-large) {
    padding-left: 4.5rem;
  }
  padding: 0vh 2vh 2vh 5vh;
  line-height: 2.5rem;
}


.plateDesignTitle {
  font-weight: 700;
  font-size: 3rem;
}

@import '../../styles/colors';
@import '../../styles/typography';
@import '../../styles/breakpoints.module';

.stretchImage {
  background-color: $color-light-grey;
  margin: 2.5rem 0;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    max-height: 40rem;
    margin: 4rem 0;
    //max-width: 1020px;
  }
}

.imageContainer {
  // position: relative;

  @media (min-width: $breakpoint-medium) {
    flex-basis: ((100% / 3) * 2);
    flex-shrink: 3;
  }

  &::before {
    content: '';
    display: block;
    padding-top: ((10 / 17) * 100%);
    width: 100%;
  }

  // by default image is displayed at 680 x 400 px (aspect ratio of 17:10)
  > .image {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.copyContainer {
  padding: 2.8rem;

  @media (min-width: $breakpoint-medium) {
    padding: 2rem;
    align-items: center;
    display: flex;
    flex-basis: (100% / 3);
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }

  .copy {
    margin-bottom: 2.8rem;

    @media (min-width: $breakpoint-medium) {
      margin: 0 1.8rem 2.8rem 3rem;
      max-width: 18rem;
    }
  }

  .title {
    @extend .h1;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 0;
  }

  .subtitle {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0;
  }
}

@import '../../styles/colors';
@import '../../styles/breakpoints.module';

%responsive-image {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
  }

  > .image {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.hero {
  background-color: $color-light-grey;
}

.logoContainer {
  background-color: $color-black;
  padding: 1rem;
}

// .copyContainer {
//   padding: 2.5rem 2.7rem 3rem;
// }

// .title {
//   font-size: 3.6rem;
//   margin: 0 0 2rem;
// }

// .subTitle {
//   margin: 0 0 3rem;
//   line-height: 1.5;
// }


.mainBannerImage {
  background-color: $color-light-grey;
  //margin: 2.5rem 0;

  @media (min-width: $breakpoint-medium) {
    display: flex;
    max-height: 40rem;
    //max-width: 1020px;
    width: 100%;
  }
}

.imageContainer {
  position: relative;

  @media (min-width: $breakpoint-medium) {
    flex-basis: ((100% / 3) * 2);
    flex-shrink: 3;
  }

  &::before {
    content: '';
    display: block;
    padding-top: ((10 / 17) * 100%);
    width: 100%;
  }

  // by default image is displayed at 680 x 400 px (aspect ratio of 17:10)
  > .image {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.imgFluidBackGrnd {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top center !important;
}

.copyContainer {
  padding: 10rem;

  @media (min-width: $breakpoint-medium) {
    padding: 10rem;
    align-items: center;
    display: flex;
    flex-basis: (100% / 3);
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }

  .copy {
    margin-bottom: 2.8rem;

    @media (min-width: $breakpoint-medium) {
      margin: 0 1.8rem 2.8rem 3rem;
      max-width: 18rem;
    }
  }

  .title {
    @extend .h1 !optional;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 0;
  }

  .subtitle {
    font-size: 1.4rem;
    line-height: 1.4;
    margin-bottom: 0;
  }
}

.bgLight{background: #e5e5e5;}
.firstSection {
    width: 100%;
    flex-direction: column-reverse;
    display: flex;
}
.designPlate {
  padding:50px 30px;
}
@media (min-width:$breakpoint-ipad){
    .firstSection {
        display: grid;
        width: 100%;
        grid-template-columns: 6.4fr 7.9fr;
        overflow: hidden;
      }
    .designPlate {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 370px;
        margin: auto;
        padding: 50px 0 90px 120px;
    }
}
@media (min-width:$breakpoint-ipad) and (max-width:'1180px'){
  .firstSection {
    grid-template-columns: 4.4fr 7.9fr;
  }
  .designPlate {
    padding: 50px 0 85px 50px;
  }
}


.w100{width: 100%;}
// .designPlate {
//     //padding: 50px 40px;
// }

.awssld__wrapper{
  max-height: 460px !important;
}
.mainAwsmSlider{
  overflow: hidden;
}

@import './_hero.desktop.scss';
@import './_hero.mobile.scss';

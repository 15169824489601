@import '../../styles/colors';
@import '../../styles/typography';
@import '../../styles/breakpoints.module';

$hover-bg-color: #f7f7f7;
//$hover-bg-color: #ffffff;
$hover-border-color: #eeeded;
$hover-transition-time: 200ms;

.plate {
  background-color: $color-white;
  overflow: hidden;
  margin: 2.5rem 1.5rem;
  position: relative;
  max-width: 20rem;
  flex-basis: 20rem;

  &::before {
    background-color: transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border: solid #fff;
    border-width: 5px 5px 0;
    bottom: 0;
    content: "";
    display: block;
    left: -1rem;
    opacity: 0;
    position: absolute;
    top: -0.6rem;
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    width: calc(100% + 5rem);
    z-index: 1;
    outline: 1px solid #efeeee;
  }

  &:hover,
  &.hover {
    overflow: visible;
    //border-top: 1px solid #efeeee !important;

    &::before {
      background: $hover-bg-color;
      opacity: 1;
    }

    > :not(.expandable) {
      position: relative;
      z-index: 2;
      // border-left: 1px solid;
      // border-right: 1px solid;
      margin: -4px;
      padding: 12px 8px;
      border-color: #efeeee;
    }

    .expandable {
      //background-color: $hover-bg-color;
      // opacity: 1;
      // visibility: visible;
      background-color: #fff;
      opacity: 1;
      visibility: visible;
    }
  }
}

.expandable {
  // border-bottom-left-radius: $border-radius;
  // border-bottom-right-radius: $border-radius;
  // border-color: $hover-border-color;
  // border-style: solid;
  // border-width: 0 1px 1px 1px;
  // left: -1rem;
  // opacity: 0;
  // padding: 0 1.2rem 1rem;
  // position: absolute;
  // right: 0;
  // top: 100%;
  // transition: opacity $hover-transition-time ease-in-out;
  // visibility: hidden;
  // width: calc(100% + 2rem);
  // z-index: 100;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #efeeee;
  border-top: 0 solid #eeeded;
  left: -1rem;
  opacity: 0;
  padding: 0 0.5rem 0.5rem;
  position: absolute;
  right: 0;
  top: 100%;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
  visibility: hidden;
  width: calc(100% + 5rem);
  z-index: 9;
}

.actions a {
  padding: 1.5rem 2rem 1.5rem 2rem !important;
  font-size: 1.7rem;
  @media (max-width: $breakpoint-small) {
    font-size: 1.1rem;
  }
}

.actions p {
  margin-top:0;
  font-size: 1.6rem;
}

.image {
  // align-items: flex-end;
  // display: flex;
  // margin: 0;
  // min-height: 7.7rem;
  // padding: 1rem 1rem 2rem 0;
  align-items: flex-end;
  display: flex;
  margin: -4px;
  min-height: 7.7rem;
  padding: 12px 8px;
  // border-left: 1px solid;
  // border-right: 1px solid;
  border-top: 1px solid transparent;
  border-color: transparent;
  border-top: 1px solid #efeeee !important;

  img {
    // Plate assets have been constrained to the following container sizes:
    //    - 300px x 77px  (1x)
    //    - 600px x 154px (2x)
    vertical-align: bottom;
    // Our listing size uses a max-height of 46px.
    max-height: 4.6rem;
    max-width: 100%;
    width: auto;
  }
}

.imageLink {
  align-self: flex-end;
  flex-basis: 100%;
  flex-grow: 0;
}

.title {
  @extend .h5;
  font-size: 2.4rem;
  letter-spacing: 0.056rem;
  margin: 0 0 0.5rem;
}

.copy {
  //padding: 0 0.2rem 0.2rem;
  padding: 12px 8px;
  margin: -4px;
  // border-left: 1px solid;
  // border-right: 1px solid;
  border-color: transparent;
}

.priceLabel {
  padding-right: 0.1rem; // extra gap between 'From' and $price
}

.price {
  font-size: 1.5rem;
  margin: 0;
  letter-spacing: 0.05rem;

  &.compact {
    font-size: 2rem;
  }
}

.mainClassExpandHover {
  padding-top: 0 !important;
}

.mainClassExpandHover p {
  margin-top: 0 !important;
  font-size: 1.75rem;
}

dl.details {
  // font-size: 1.4rem;
  // margin-bottom: 4rem;
  // margin-top: 3.2rem;
  font-size: 1.8rem;
  padding-bottom: 4rem;
  padding-top: 3.2rem;
  margin: 0;

  border-left: 1px solid;
  border-right: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
  background: #f7f7f7;
  border-color: #efeeee;

  dd {
    margin-left: 0;
  }
}

.label {
  font-weight: bold;
}

.labelInline {
  line-height: 1em;
  margin-top: 1em;

  dt,
  dd {
    display: inline-block;
  }

  dt {
    font-size: 1.6rem;
    font-weight: bold;
    margin-right: 0.4rem;
  }
  dd {
    font-size: 1.4rem;
  }
}

.actions {
  font-size: 1.4rem;
  text-align: center;
  border-bottom: 1px solid;

  border-left: 1px solid;
  border-right: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
  background: #f7f7f7;
  border-color: #efeeee;

  p {
    line-height: 1.4;
  }

  button,
  a {
    margin-bottom: 2.5rem;
  }
}

.link {
  border-bottom: 1px solid currentColor;
  color: $color-black;
  display: inline-block;
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  line-height: 0.8;
  margin: 0 0 2rem;
  padding-top: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: border-bottom-color 200ms ease-in-out;

  &:hover {
    border-bottom-color: transparent;
  }
}
.mainClassAvaialbleStore {
  // margin-bottom: 100px !important;
  // margin-top: 0 !important;
  // font-size: 1.5rem;
  // padding-left: 7px;
  margin-bottom: 10px !important;
  margin-top: 5px !important;
  font-size: 1.5rem;
  padding-left: 2px;
  @media (max-width: $breakpoint-small) {
    font-size: 1.3rem !important;
  }
}
.mainClassPlateSeriesHide{
  display: none !important;
}

@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.plateGrid {
  padding-bottom: 0;
}

.count {
  text-align: right;
  padding: 0 1.8rem 0 1rem;
  padding-right: 1.8rem;
  margin-top: -45px;

  @media (min-width: $breakpoint-small) {
    padding-right: 2.8rem;
    margin-top: -45px;
  }

  @media (min-width: $breakpoint-tablet) {
    text-align: left;
    margin-top: 0px;
  }
}

.list {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: $breakpoint-tablet) {
    margin: 0 0.2rem 0rem -1.2rem;
    justify-content: flex-start;
  }

  @media (max-width: $breakpoint-medium) {
    margin: 0 -1rem 0 -1.2rem;
    justify-content: flex-start;
    padding: 0vh 2vh 2vh 1vh;
  }

}

.listItem {
  flex-basis: 17rem;
  margin: 0;
  padding: 0 0.7rem 2.5rem;

  @media (min-width: $breakpoint-medium) {
    flex-basis: 25rem;
    max-width: 25rem;
    padding: 0 1.5rem 5rem;
  }

  .plate {
    margin: 0;
    max-width: 100%;
  }
}

.listItem1 {
  margin: 0;
  //.mainGridPlatinumClass > .listItem1{padding-bottom: 0 !important;}
  @media (min-width: $breakpoint-medium) {
    flex-basis: 22.7rem;
    max-width: 22.7rem;
  }
}

.classNewGrid {
  padding-bottom: 0 !important;
}

.classNewGridUL {
  margin-bottom: 14rem !important;
}

.noResults {
  .noResultsCopy {
    margin: 5rem 0;
  }

  .loading {
    margin-top: 2rem;
    font-size: 1.2rem;

    @media (max-width: $breakpoint-tablet - 1) {
      margin-top: 9rem;
    }
  }
}

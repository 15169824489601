@import '../../styles/variables';
@import '../../styles/colors';

.button {
  background-color: $color-black;
  border-radius: $border-radius;
  border: 1px solid $color-brand-amber;
  color: $color-white;
  display: inline-block;
  font-size: 1.8rem;
  font-family: $font-family-body;
  font-weight: bold;
  letter-spacing: 0.3rem;
  line-height: 1;
  overflow: hidden;
  padding: 1.1rem 2.6rem 0.9rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 200ms ease-in-out;
  vertical-align: middle;
  white-space: nowrap;

  &.buttonLarge {
    padding: 2.2rem 3rem 1.8rem;
  }

  &:hover,
  &:focus {
    .tetriminoTopLeft {
      transform: translate(-0.3rem, -0.2rem);
    }

    .tetriminoTopRight {
      transform: scale(-1, 1) translate(-0.3rem, -0.2rem);
    }

    .tetriminoBottomRight {
      transform: scale(-1, -1) translate(-0.3rem, -0.2rem);
    }

    .tetriminoBottomLeft {
      transform: scale(1, -1) translate(-0.3rem, -0.2rem);
    }
  }

  &:hover {
    box-shadow: 0 0 0.5rem 0 $color-brand-amber;
  }
}

.buttonOutline {
  background-color: transparent;
  color: $color-brand-black;
  border-color: $color-brand-black;

  .tetrimino {
    svg {
      fill: $color-brand-black;
    }
  }

  &:hover {
    box-shadow: 0 0 0.5rem 0 $color-dark-grey;
  }
}

.tetrimino {
  position: absolute;
  transform-origin: center;
  transition: transform 200ms ease-in-out;
  z-index: 0;

  &.tetriminoTopLeft {
    top: 0;
    left: 0;
  }

  &.tetriminoTopRight {
    transform: scale(-1, 1) translate(0, 0);
    top: 0;
    right: 0;
  }

  &.tetriminoBottomRight {
    transform: scale(-1, -1) translate(0, 0);
    bottom: 0;
    right: 0;
  }

  &.tetriminoBottomLeft {
    transform: scale(1, -1) translate(0, 0);
    bottom: 0;
    left: 0;
  }

  svg {
    fill: $color-brand-amber;
    vertical-align: top;
  }
}

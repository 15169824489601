@import '../../styles/colors';

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -0.7rem;
  padding: 0;
}

.listItem {
  margin: 0;
}

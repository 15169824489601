@import './variables';

/// Gotham XNarrow Book
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fonts/GothamXNarrow-Book.woff') format('woff');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fonts/GothamXNarrow-BookItalic.woff') format('woff');
}

/// Gotham XNarrow Bold
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('./assets/fonts/GothamXNarrow-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('./assets/fonts/GothamXNarrow-BoldItalic.woff') format('woff');
}

/// Gotham XNarrow Extra Light
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: $font-extra-light;
  font-display: swap;
  src: url('./assets/fonts/GothamXNarrow-XLight.woff') format('woff');
}

@font-face {
  font-family: 'Gotham';
  font-style: italic;
  font-weight: $font-extra-light;
  font-display: swap;
  src: url('./assets/fonts/GothamXNarrow-XLightItalic.woff') format('woff');
}

@import '../../styles/colors';

.label {
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;
  border: 1px solid transparent;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: transform 200ms ease-in-out;

  &.labelLight {
    color: $color-black;
  }

  &.labelDark {
    color: $color-white;
  }

  &.labelBorder {
    border-color: $color-black;
  }
}

.colorCheckbox {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  padding: 0.7rem;

  .labelScaled {
    transform: scale(1.363636364);
  }
}

.checkbox {
  cursor: inherit;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  &:not([disabled]):focus + .label,
  &:not([disabled]):active + .label {
    box-shadow: 0 0 0.3rem 0.1rem rgba($color-black, 0.5);
  }

  &[disabled],
  &[disabled] + .label {
    cursor: default;
  }
}

.icon {
  display: inline-block;
  fill: currentColor;
  opacity: 0;
  transform: scale(0.733); // reverse scale of the label
  transition: opacity 100ms ease-in-out;
  user-select: none;
}

.iconVisible {
  opacity: 1;
}

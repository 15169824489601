@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}
.mainBizGuideIframe{
  height: 100vh;
  @media (max-width: $breakpoint-large) and (min-width: $breakpoint-ipad) {
    height: 155vh;
  }
  @media (min-width: $breakpoint-small) and (max-width:$breakpoint-tablet){
    height: 270vh;
  }
  @media (min-width: $breakpoint-medium) and (max-width:$breakpoint-large){
    height: 120vh;
  }
  @media (max-width: $breakpoint-small){
    height: 320vh;
  }
  @media (min-width: $breakpoint-large) {
    height: 85vh;
  }

}

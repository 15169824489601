@import '../../styles/variables';

.container {
  margin: 0 auto;
  max-width: $container-width;
  width: 100%;
}

.full {
  max-width: none;
}

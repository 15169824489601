@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

// .header {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   background-color: $color-black;
//   color: $color-white;
//   padding: 1.6rem 2.8rem;
// }

// .nav {
//   bottom: 0;
//   position: fixed;
//   top: 0;
//   transform-origin: 0 0;
//   transform: translate(-100%, 0);
//   transition: transform 500ms ease-in-out, box-shadow 0s linear 400ms;
//   z-index: 1;
// }

// .navOpen {
//   display: block;

//   @media (min-width: $breakpoint-tablet) {}
// }

// .navList {
//   display: flex;
// }

// .navToggle {
//   display: block;

//   @media (min-width: $breakpoint-tablet) {
//     display: none;
//   }
// }
body {
  padding-top: 95px;
}
@media (min-width:$breakpoint-ipad){
  body {
    padding-top: 80px;
  }
}
.navbar input[type="checkbox"],
.navbar .hamburgerLines{
    display: none;
}
.imgFluid {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}
.navbar{
    width: 100%;
    background: $color-black;
    color: $color-white;
    position: fixed;
    top:0;
    z-index: 100;
}

.navbarContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    // max-width: 1390px;
    width: 90%;
    margin: auto;
}
.menuItems{
    display: flex;
}
.logo{
    height: 50px;
    display: flex;
    align-items: center;
}

.menuItems li{
    list-style: none;
        margin-left: 3rem;
}

.navbar ul {
  margin: 0;
}
.navbar ul li a{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease-in-out;
    text-transform: uppercase;
    font-size: 1.8rem;
    letter-spacing: 5px;
    padding: 17px 0;
    display: block;
}

.navbar a:hover{
    color: #f9c446;
}

@media (max-width: $breakpoint-tablet){
    .menuItems li{ margin-left: 0;}

    .navbarContainer input[type="checkbox"],
    .navbarContainer .hamburgerLines{
        display: block;
    }

    .navbarContainer{
        display: block;
        position: relative;
        padding:18px 0 !important ;
    }

    .navbarContainer input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 36px;
        top: 0;bottom: 0;margin: auto;
        right: 0;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbarContainer .hamburgerLines{
        display: block;
        height: 18px;
        width: 24px;
        position: absolute;
        top: 0;bottom: 0;margin: auto;
        right: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbarContainer .hamburgerLines .line{
        display: block;
        height: 2px;
        width: 100%;
        border-radius: 10px;
        background: #fff;
    }

    .navbarContainer .hamburgerLines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbarContainer .hamburgerLines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbarContainer .hamburgerLines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menuItems{
        padding-top: 60px;
        background: #000;
        height: 100vh;
        width: 0;
        right: 0;
        flex-direction: column;
        margin-right: -80px;
        transition: width 0.3s ease-in-out;
        overflow: scroll;
        position: absolute;
    }

    .navbar .menuItems li{
        margin-bottom: 1.8rem;
    }
@media (max-width:$breakpoint-small){
    .navbar a{font-size: .7rem;}
    .navbarContainer input[type="checkbox"]:checked ~ .menuItems{width: 210px;padding-left: 30px;}
}

    .navbarContainer input[type="checkbox"]:checked ~ .menuItems{
       width: 250px;padding-left: 40px; margin-right:-40px
    }

    .navbarContainer input[type="checkbox"]:checked ~ .hamburgerLines .line1{
        transform: rotate(45deg);
    }

    .navbarContainer input[type="checkbox"]:checked ~ .hamburgerLines .line2{
        transform: scaleY(0);
    }

    .navbarContainer input[type="checkbox"]:checked ~ .hamburgerLines .line3{
        transform: rotate(-45deg);
    }

}

@media (max-width: $breakpoint-small){
    .navbarContainer input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
}



@import '../../styles/colors';
@import '../../styles/breakpoints.module';

$circle-size: 4.8rem;

.step {
  align-items: center;
  display: inline-flex;
  list-style: none;
  padding: 0.5rem 0;
  position: relative;
  flex-direction: column;
  flex-basis: 21rem;

  @media (min-width: $breakpoint-medium) {
    flex-direction: column;
    padding: 1rem 2rem 2rem;
    text-align: center;
  }

  @media (max-width: $breakpoint-small) {
    li:last-child span.step_iconContainer svg {
      width: 59px;
      height: 64px;
    }
    span.step .iconContainer svg {
      width: 38px;
    }
  }

  .mainHowItWorksCheckClass {
    //@media (min-width: $breakpoint-small) {
      height: 80px !important;
      width: 80px !important;
    //}
  }

  .mainHowItWorksCheckLabel {
    text-align: center;
    font-size: 13px !important;
    line-height:1.2;
    @media (min-width: $breakpoint-medium) {
      text-align: left !important;
      padding-left: 12px !important;
    }
  }
  .mainHowItWorksCheckCircle {

    font-size: 12px !important;
    height: 2.8rem !important;
    width: 2.8rem !important;
    @media (min-width: $breakpoint-medium) {
      font-size: 15px !important;
    height: 1.8em !important;
    width: 2.8rem !important;
      left: 2rem !important;
      top: -10px !important;
    }
  }

  .mainHowItWorksCheckTick {
    right: 46px !important;
    left: auto;
    top: -3px;
    height: 22px !important;
    width: 22px !important;
    @media (min-width: $breakpoint-medium) {
      right: auto !important;
      left: 76px !important;
      top: 3px !important;
      height: 22px !important;
      width: 22px !important;
    }
  }
  .mainHowItWorksCheckTick svg {
    transform: scale(1) translateY(-0.14rem) !important;
    @media (min-width: $breakpoint-small) {
    }
  }

  .iconContainer {
    // display: flex;
    // flex-basis: 6rem;
    // flex-shrink: 0;
    // justify-content: center;
    // margin: 0 2rem;
    // order: 1;
    display: flex;
    flex-basis: unset;
    flex-shrink: 0;
    justify-content: center;
    margin: 0 2rem;
    order: 1;
    background: #000;
    height: 80px;
    width: 80px;
    align-items: center !important;
    padding: 10px;
    border-radius: 50%;

    @media (min-width: $breakpoint-small) {
      margin-right: 2rem;
      height: 97px;
      width: 97px;
    }

    @media (min-width: $breakpoint-medium) {
      align-items: flex-end;
      //height: 7rem;
      margin: 0;
      order: 0;
     // width: auto;
    }
  }

  .iconContainer svg g {
    fill: #fff;
  }

  .circle {
    // align-content: center;
    // align-items: center;
    // background-color: $color-brand-amber;
    // border-radius: 50%;
    // display: inline-flex;
    // flex-shrink: 0;
    // font-weight: bold;
    // height: $circle-size;
    // justify-content: center;
    // margin: 1.5rem 0;
    // padding-top: 0.4rem;
    // width: $circle-size;
    // order: 0;
    align-items: center;
    background-color: #ffc433;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    font-weight: bold;
    height: 2.8rem;
    justify-content: center;
    margin: 1.5rem 0;
    padding-top: 0.1rem;
    width: 2.8rem;
    order: 0;
    position: absolute;
    top: -14px;
    left: 1.5rem;

    @media (min-width: $breakpoint-medium) {
      top: -13px;
      left: 5.5rem;
    }

    &[data-step-number]:before {
      content: attr(data-step-number);
    }

    .tick {
      // nicely align the tick inside the circle
      transform: scale(1.5) translateY(-0.14rem);
    }
  }

  .label {
    // max-width: 100%;
    // order: 2;
    max-width: 100%;
    order: 2;
    margin-top: 6px;
    padding: 0 5px;

    @media (max-width: $breakpoint-small) {
      margin-top: 20px;
      font-size: 14px;
      padding: 0 8px;
      line-height: 1.3;
    }

    br {
      content: ' ';

      &::after {
        content: ' ';
      }

      @media (min-width: $breakpoint-medium) {
        content: normal;
      }
    }
  }

  .mainTick {
    right: 54px !important;
    left: auto;
    background: #ffc433 !important;
    top: 2px;
    padding-top: 4px;
    @media (min-width: $breakpoint-medium) {
      right: 61px !important;
      top: 9px;
      padding-top: 4px;
    }
    @media (max-width: $breakpoint-medium) {
      right: 46px !important;
    }
  }


}

@import-normalize;
@import 'styles/variables';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/typography';

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  font-size: 62.5%;
}

body {
  background-color: $color-black;
  font-size: $font-default-size;
  margin: 0;
  font-family: $font-family-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @extend .h1;
}

h2 {
  @extend .h2;
}

h3 {
  @extend .h3;
}

h4 {
  @extend .h4;
}

h5 {
  @extend .h5;
}

h6 {
  @extend .h6;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input:not([type]),
textarea {
  font-family: inherit;
}

#root {
  background-color: $color-white;
}


// HACK: Hide dev overlay iframe
body>iframe {
  display: none;
}


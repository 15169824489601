@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

// .footer {
//   display: flex;
//   //align-items: center;
//   position: sticky;
//   bottom: 0;
//   background-color: $color-black;
//   color: $color-white;
//   padding: 1.6rem 2.8rem;
// }
// .subFooterMenuClass {
//   border-left: 1px solid #fff;
//   padding-left: 6px;
//   margin-left: 6px;
//   bottom: 0;
// }
// .govtLogo {
//   margin-left: 10px;
// }

.footer_logo_container {
  display: flex;
  align-items: center;
  justify-content:space-between;
}

.footerLogoContGov a:first-child {
  margin-right: 50px;
  @media(max-width:667px){
    margin-right: 20px;
  }
}

.footer {
  padding: 25px 45px 30px 70px;
  display: flex;
  flex-direction: column;
  background-color: $color-black;
}

.footer_logo_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.footerLogoContGov{display: flex;align-items: center;}
.footer ul li:hover a,
.footer ul li a.active {
  color: #f9c446;
  transition: .3s all;
}

.footer ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-size: 1.3rem;
}
.footer ul li:last-child {
  margin-right: 0;
}

.footer ul li:last-child:after {
  content: unset;
}

.footer ul {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer a:last-child img {
  width: 215px;
}

.footer ul li:last-child:after {content: unset !important;}
.footer ul li:after {
  content: "|";
  display: block;
  color: #fff;
  position: absolute;
  right: -12px;
  top: 6px;
  font-size: 12px;
  font-weight: 300;
  @media (max-width: $breakpoint-small) {
    top: 5px;
  }
}

.footer ul li {
  margin-right: 22px;
  position: relative;
}

@media(max-width:1024px) {
.footer_logo_container {
  margin-top: 20px;
  margin-bottom: 0;
  justify-content: center;
  flex-direction: column;
}
  .footer ul {
      justify-content: center;
      margin-bottom: 20px;
      padding: 0 10px;
  }



  .footer ul li {
      margin-bottom: 12px;
  }
.footer ul li a{font-size:1.5rem}
.footer img{width:110px}
  .footer {
      padding:40px 30px;
      //flex-direction: column-reverse;
  }
}
.active {
  color: #f9c446 !important;
}

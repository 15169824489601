.mobileContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;

  .column {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    // fallback styles for when there are no/not enough images to display.
    &.logoOnly {
      flex-basis: 100%;

      .logoContainer {
        padding: 2.7rem;
        justify-content: flex-start;
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
  }

  .image1 {
    @extend %responsive-image;
    flex: 1 1;

    &::before {
      padding-top: (125 / 187) * 100%;
    }
  }

  .image2 {
    @extend %responsive-image;
    &::before {
      padding-top: (235 / 188) * 100%;
    }
  }

  .copyContainer {
    flex-basis: 100%;
  }
}

@import '../../styles/colors.scss';
@import '../../styles/breakpoints.module.scss';
@import '../../styles/variables.scss';

.message {
  padding: 2rem;
  background: $color-black;
  color: $color-white;
}

.marginMessage {
  margin-bottom: 2rem;
}

.errorMessage {
  background: $color-error;
}

.successMessage {
  background: $color-success;
}

.warningMessage {
  background: $color-brand-amber;
}

.infoMessage {
  background: $color-blue;
}

@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}
.mainPrivacyIframe{
  height: 500vh;
  @media(max-width:480px){
      height: 750vh;
  }
}

@import '../../styles/variables';
@import '../../styles/colors';

.logo {
  vertical-align: top;
  width: 155px;
}

.logoFooter {
  vertical-align: top;
  width: 125px;
}

.link {
  border-radius: $border-radius;
  border: 0.2rem solid transparent;
  display: inline-block;
  outline: 0;
  padding: 0.2rem;
  transition: border-color 150ms ease-in-out;

  &:hover,
  &:focus {
    border-color: $color-brand-amber;
  }
}

@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.filterSidebar {
  background-color: $color-white;
  flex: 0 0 28rem;
  max-width: 28rem;
  // off-screen state:
  bottom: 0;
  position: fixed;
  top: 16px;
  margin-top: 80px;
  transform-origin: 0 0;
  transform: translate(-100%, 0);
  transition: transform 500ms ease-in-out, box-shadow 0s linear 400ms;
  z-index: 1;

  &.filterSidebarOpen {
    box-shadow: 1.1rem 0.5rem 4.4rem rgba($color-black, 0.2);
    transition: transform 500ms ease-in-out, box-shadow 50ms ease-in;
    transform: translate(0, 0);
  }

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }

  @media (min-width: $breakpoint-tablet) {
    border-right: 1px solid #e4e4e4;
    flex-basis: 26rem;
    max-width: 26rem;
    // default state:
    box-shadow: none;
    overflow: visible;
    position: static;
    transform: none;
    transition: none;
    top:0;
    margin-top: 0;
    &.filterSidebarOpen {
      box-shadow: none;
    }
  }

  @media (min-width: 1000px) {
    flex-basis: 32rem;
    max-width: 32rem;
  }
}

.filterOptions {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 6.5rem);
  max-height: calc(100% - 6.5rem);
  overflow-y: auto;
  padding: 1rem 3rem;
  position: relative;
  scroll-behavior: smooth;

  @media (min-width: $breakpoint-tablet) {
    overflow-y: visible;
    scroll-behavior: auto;
    padding-bottom: 4rem;
    max-height: none;
    flex-basis: auto;
  }

  @media (min-width: 1000px) {
    padding: 1rem 5rem 4rem 6.8rem;
  }
}

.filterForm {
  flex-direction: column;

  @media (max-width: $breakpoint-tablet) {
    display: flex;
    height: 100%;
  }
}

.filterHeading {
  background: url('../../assets/images/icon-filter.svg') no-repeat right 35%;
  font-size: 2.4rem;
  letter-spacing: 0.075rem;
  margin: 2rem 0;
  text-transform: uppercase;
  @media (min-width: $breakpoint-tablet) {
    background-image: unset;
  }
}

.filterSidebarButtonsContainer {
  background-color: $color-white;
  border-top: 1px solid $color-black;
  box-shadow: 0 -0.1rem 1.3rem rgba($color-black, 0.13);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6.5rem;
  height: 6.5rem;
  width: 100%;

  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.filterSidebarButtons {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 28rem;
  padding: 1.2rem 1.7rem 1.2rem 4rem;
}

.clearButton {
  background-color: $color-white;
  border: 0;
  border-bottom: 1px solid currentColor;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  line-height: 0.9;
  margin: 0 1rem;
  padding: 0.3rem 0 0;
  text-transform: uppercase;
}

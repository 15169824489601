@import '../../styles/colors';

.filterOption {
  border-top: 1px solid $color-black;
  padding: 1.1rem 0 2.4rem;

  h3,
  .filterOptionTitle {
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.044rem;
    margin: 0 0 1rem;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  svg {
    width: 10px;
    height: 8px;
  }
}

@import './variables';

.h1 {
  font-size: 3.2rem;
}

.h2 {
  font-size: 2.8rem;
}

.h3 {
  font-size: 2.4rem;
  font-weight: normal;
}

.h4 {
  font-size: 1.8rem;
}

.h5 {
  font-size: 1.8rem;
  font-weight: normal;
}

.h6 {
  font-size: 1.4rem;
  font-weight: $font-extra-light;
  text-transform: uppercase;
}

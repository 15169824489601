@import '../../styles/variables';
@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.headerBg {
  background-color: $color-black;
  color: $color-white;
}
.mainCollectionIframe{
  height: 250vh;
  @media(max-width:480px){
      height: 470vh;
  }
}

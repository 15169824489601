.desktopContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 8rem;
  margin-bottom: 3.4rem;

  // fallback styles for when there are no/not enough images to display.
  &.logoOnly {
    display: block;

    .column,
    .column:first-child,
    .column:last-child {
      padding: 0;
    }

    .logoContainer {
      justify-content: flex-start;
      padding: 2.7rem;
      flex-basis: auto;
    }

    .title {
      max-width: none;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 33.4rem;
    padding: 7.5rem 0 3rem;

    &:first-child {
      // the column with the logo in it displays in the middle
      order: 1;
      padding-top: 1.5rem;
      flex-basis: 24.2rem;
    }

    &:last-child {
      // the last column stays the last and forces the 1nd into the middle
      order: 2;
      padding-top: 0;
      flex-basis: 33.4rem;
    }
  }

  .image1 {
    @extend %responsive-image;
    &::before {
      padding-top: (219 / 334) * 100%;
    }
  }

  .image2 {
    @extend %responsive-image;
    &::before {
      padding-top: (316 / 242) * 100%;
    }
  }

  .image3 {
    @extend %responsive-image;
    width: 27.5rem;

    &::before {
      padding-top: (344 / 275) * 100%;
    }
  }

  .image4 {
    @extend %responsive-image;
    &::before {
      padding-top: (196 / 334) * 100%;
    }
  }

  .copyContainer {
    align-items: flex-start;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: -3rem;
    padding-bottom: 0;
  }

  .title {
    font-size: 5rem;
    max-width: 21rem;

    // if it just the title and button, we need more space.
    + a {
      margin-top: 2.6rem;
    }
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 13.4rem;

    img {
      // Scale logo to 134 x 40px
      width: 13.4rem;
      height: auto;
    }
  }
}

@import '../../styles/colors';

.checkbox {
  align-items: flex-start;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.03rem;
  margin: 0.5em 0.5em 0.5em 0;
  position: relative;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
}

.customCheckbox {
  align-items: inherit;
  border-radius: 2px;
  border: 1px solid $color-black;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: inherit;
  padding: 0.2rem 0.2rem 0.3rem 0.1rem;
  transition: box-shadow 150ms ease-in-out;
}

.radio {
  border-radius: 50%;
  padding: 0.2rem;

  > .icon {
    background-color: $color-black;
    color: $color-black;
    border-radius: inherit;
    display: inline-block;
    height: 1rem;
    width: 1rem;
  }
}

.input {
  cursor: inherit;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1.6rem;

  &:not([disabled]):focus + .customCheckbox,
  &:not([disabled]):active + .customCheckbox {
    box-shadow: 0 0 0.3rem 0.1rem rgba($color-black, 0.5);
  }
}

.label {
  padding-left: 2rem;

  &.capitalise {
    text-transform: capitalize;
  }

  &.withSize {
    display: flex;
    width: 17.5rem;
    max-width: 100%;

    .sizeTitle {
      flex-grow: 1;
    }

    .sizeText {
      flex-shrink: 0;
      text-transform: none;
    }
  }

  .sizeComboText {
    text-transform: none;
  }
}

.icon {
  fill: currentColor;
  flex-shrink: 0;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
  user-select: none;
}

.iconVisible {
  opacity: 1;
}

//$color-brand-black: #231f20;
$color-brand-black: #000000;
$color-brand-amber: #ffc433;

$color-black: $color-brand-black;
$color-white: #fff;
$color-orange: #ff9800;
$color-red: #e70f00;
$color-pink: #e91d63;
$color-blue: #2196f3;
$color-teal: #00b4a6;
$color-deep-purple: #683ab7;
$color-indigo: #1f4ab7;
$color-green: #00875d;
$color-light-green: #8bc34a;
$color-grey: #979797;
$color-dark-grey: #727273;
$color-light-grey: #e5e5e5;

$brand-primary: $color-brand-amber;

$color-success: $color-green;
$color-error: $color-red;

:export {
  brandBlack: $color-brand-black;
  brandAmber: $color-brand-amber;

  brandPrimary: $brand-primary;

  black: $color-black;
  white: $color-white;
  grey: $color-dark-grey;

  success: $color-success;
  error: $color-error;

  colorOrange: $color-orange;
  colorRed: $color-red;
  colorPink: $color-pink;
  colorBlue: $color-blue;
  colorTeal: $color-teal;
  colorDeepPurple: $color-deep-purple;
  colorIndigo: $color-indigo;
  colorGreen: $color-green;
  colorLightGreen: $color-light-green;
  colorGrey: $color-dark-grey;
}

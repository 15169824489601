@import '../../styles/colors';
@import '../../styles/breakpoints.module';

.skyscraper {
  //background-color: $color-light-grey;
  display: none;
  //flex-basis: 24rem;
  flex-basis: 32rem;
  flex-shrink: 0;
  // margin: 2.5rem 0;
  //max-width: 24rem;
  max-width: 32rem;
  overflow: hidden;

  @media (min-width: $breakpoint-tablet) {
    display: block;
  }
}

.imageContainer {
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: (11 / 8) * 100%;
    width: 100%;
  }

  > .image {
    background-position: right;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
